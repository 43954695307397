import { useEffect } from "react";
import CarouselComponent from "../components/CarrouselComponent";
import { addSeries } from "../firebase-actions";

export default function HomePage() {

  // useEffect(() => {
  //   const add = async () => {
  //     await addSeries({
  //       id: "zee",
  //       title: "Zee",
  //       description: "Allemaal zee",
  //       images: []
  //     })
  //   }
  //   // add();
  // },[])

  return (
    <div className="flex flex-col gap-6">
      <div className="font-gray-500 font-semibold">
        <p className="mb-2">Welkom op mijn website!</p>
        <p className="mb-2">Mijn naam is Ans Christiaens. Kijk rustig rond op deze website om mijn werk te bekijken.</p>
      </div>
      <div className="p-4 font-gray-500 font-semibold bg-slate-100 rounded flex flex-col sm:flex-row gap-6">
      <div className="max-w-[150px] flex flex-col justify-center"><img src="logo_smeltkroes.png" alt="smeltkroes" className="" /></div>
        <div className="flex-1 mt-4">
          <p className="mb-2">Vanaf woensdag 13 november is mijn werk te zien bij De Smeltkroes in Maarheze.
            Kijk op <a href="https://www.akdesmeltkroes.nl/producten/exposities" target="_blank" className="text-blue-600">De Smeltkroes exposities</a> voor meer informatie.
          </p>
        </div>
      </div>
      <div className="flex">
        <CarouselComponent />
      </div>      
    </div>
  )
}