import { useState } from 'react';
import Tabs from './components/Tabs';
import { EnvelopeIcon, HomeIcon, PaintBrushIcon, UserIcon } from '@heroicons/react/20/solid';
import HomePage from './pages/Home';
import WorkPage from './pages/Work';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import { Routes, Route } from 'react-router-dom';
import SeriesPage from './pages/Series';

function App() {

  const tabs = [
    { name: 'Home', icon: HomeIcon, path: "/" },
    { name: 'Mijn werk', icon: PaintBrushIcon, path: "/work" },	
    { name: 'Over mij', icon: UserIcon, path: "/about" },
    { name: 'Contact', icon: EnvelopeIcon, path: "/contact" },
  ]

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center bg-slate-50 p-4">
      <div className='h-full w-full max-w-[1000px] bg-white p-6 pt-2 flex flex-col gap-4'>
        <Tabs tabs={tabs} />
        <div className='flex-1 overflow-auto'>
          <div className='flex py-2 text-2xl font-semibold  text-gray-500'>{tabs.find((tab) => tab.name === window.location.pathname)?.name}</div>
          <div className='flex-1'>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/work" element={<WorkPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />

              <Route path="/series/:id" element={<SeriesPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
