function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export type Tab = {
  name: string; 
  path: string; 
  icon: any;
}

type Props = {
  tabs: Tab[];
}

export default function Tabs(props: Props) {
  const { tabs } = props

  const currentTab = window.location.pathname;

  const onChange = (tab: Tab) => {
    window.location.href = tab.path;
  }

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>        
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md px-4 py-2 bg-gray-100 focus:border-indigo-500 focus:ring-indigo-500 text-gray-500 font-medium"
          defaultValue={tabs.find((tab) => tab.path === currentTab)?.name}
          onChange={(e) => onChange(tabs.find((tab) => tab.name === e.target.value) as Tab)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          <div className="h-12 w-12"><img src="/exlibris.jpg" alt="ex libris" /></div>
            {tabs.map((tab) => (
              <div
                key={tab.name}
                className={classNames(
                  tab.path === currentTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                onClick={() => onChange(tab)}
              >
                <tab.icon
                  className={classNames(
                    tab.path === currentTab ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
