import { series } from "../data";

export default function CarouselComponent() {
  return (
    <div className="carousel w-full max-w-[500px] h-[400px]">
      {series.map((serie, index) => (
        <div id={`slide${index}`} className="carousel-item relative w-full flex justify-center">
          <img src={`${serie.id}/${serie.images[0]}.JPG`} alt={`img-${index + 1}`} />
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href={`#slide${index === 0 ? (series.length - 1) : (index - 1) }`} className="btn btn-circle">❮</a>
            <a href={`#slide${index === (series.length - 1) ? 0 : index + 1}`} className="btn btn-circle">❯</a>
          </div>
        </div>
      ))}      
    </div>
  )
}
