export const series = [
  { 
    id: 'zee',
    title: 'Zee',
    description: `Daar waar zee en lucht elkaar raken en één worden. Ik werk met bijenwas en pigmenten op houten panelen. Dit noemt men ‘encaustiek ‘, ook wel bekend als hete was schilderen, door bijenwas te verwarmen en gekeurde pigmenten hieraan toe te voegen.`,
    images: [
      'BB1411B0-61BE-4925-AFD8-C4AA03A6577F',
      '880E2C4D-3549-4893-B9B8-3A23AAEB6C25',
      'EDC47459-F294-49A1-98DA-0507B21E53F1',
      '309BB026-F24B-4041-AB43-57FCAB87BD29',
      '29A06198-95AB-4147-8B48-E7F0F8655AC0',
      '86705A2F-2665-4E7F-9855-EE02D280FED1',
      '3C0ABA7F-A3C7-4A7F-A1B4-85E1E62ECA54',
      'F9EB64CB-32E8-4A16-AF15-D769F4F903C8',
      'F2B2A263-8B7C-4C73-8B47-7F20B488F26A',
      'F54F54F2-B05F-4AF9-A9FF-D3977ADB6899',
      '892CE8C6-9852-4F58-AF8B-0F8B614CE676',
      'C7B3D638-C374-4ED5-A45F-F24D7B1ED300',
      '49E5BEE3-ACAE-45A7-BA69-86A69FD13096',
      'CA50875F-0FD2-429F-834E-FB446C099D3B',
      'was-1',
      'was-2',
      'was-3',
      'was-4',
      'was-5',
      'was-6',
      'was-7',
      'was-8',
      'was-9',
    ]
  },
  {
    id: 'papier',
    title: 'Collage papier',
    description: `In mijn collages maak ik gebruik van gescheurde papiertjes op een ondergrond van papier. De uitdaging is om vorm, kleur en compositie naar mijn hand te zetten en de gelaagdheid van het werk te laten zien. Ik kies voor speelse taferelen.`,
    images: [
      '65FDE9D8-99C7-442D-8BBA-45189CC1974A',
      'A18AF447-6A20-4377-82B7-2BEA9DED338B',	
      '4A034632-3AB5-4F93-9A7C-4EAB564A930F',
      '7667AEB9-8ACA-4C51-B760-2CFB01109AA1',
      '0F430F43-F84A-4193-9559-66FC85577CAF',     
      '3267CC10-B7EC-4037-8D17-7EA5A65BAEBC', 
      '0D920760-1FDF-4C07-8746-3D3FAF76005C',
      '5731AFD3-C5D2-4446-B73F-6201F4B25154',
      '9659683E-6099-4D1A-8A76-05D162B263EE',
      '26946391-0191-4F96-BC2F-A68C4F7A975D',
      'F619F2B8-0AF7-4349-964B-8AF613D785E9'
    ]
  },
  {
    id: 'encaustiek',
    title: 'Collage encaustiek',
    description: `In mijn collages encaustiek  maak ik gebruik van bijenwas op houten paneeltjes, met pigmenten, papiertjes, krassen, laag over laag, zoekend  naar een abstract geheel.`,
    images: [
      '93E8C01F-B57A-4E17-8D19-7DE05705E79C',
      '06A4FCD0-CC5F-4CA6-9ED3-852196C99BF6',
      '9C706FD9-53A9-4060-9A9B-D91E37E05A23',
      'F2F9779C-D357-475A-9734-095829522D02',
      '48B43569-A5DD-4185-A9F9-D4795C174083',
    ]
  },
  {
    id: 'figuratief',
    title: 'Figuratief',
    description: `In het figuratieve schilderen, vaak naar life model in korte standen, gebruik ik een vlotte lijn of penseelstreek, die een abstract beeld opleveren.`,
    images: [
      '464CD19D-A3A8-4B61-9EA1-1443E49D2199',
      'D2597F6F-B9FB-4878-A2B8-244A27B84FAB',
      '8A6201B5-80ED-47F7-A982-A007F5D93125',
      '29123CB1-0A6B-4119-AFB0-F401C9FAE532',
      'C5644F43-3BC0-40B1-BC4B-20253B0FE797',
      'D3869CB3-C853-4C55-A7FC-77DF18D0744A',
      'D8897728-162F-4C8F-9AF5-C118043F6282',
      'E73719D9-9A6D-4803-8008-0B726A004F60'
    ]
  },
  {
    id: 'portretten',
    title: 'Portretten',
    description: `Ik druk me graag uit in kleur. In deze portretten gebruik ik net als bij de andere figuratieve schilderingen graag een vlotte lijn of penseelstreek. `,
    images: [
      '996E071F-9BEF-41C8-B9AF-4AD63C3D5731',
      'A62CD091-F357-4C8F-B9D5-417BE4655FE9',
      'F7BA3EA9-1D2D-4AD8-AC83-747AFEC17E8E',
    ]
  }
]